import React from "react";
import { Link } from 'react-router-dom';

import thumbnailImage1 from "../assets/img/portfolio/thumbnails/1.jpg";
import thumbnailImage2 from "../assets/img/portfolio/thumbnails/2.jpg";
import thumbnailImage3 from "../assets/img/portfolio/thumbnails/3.jpg";
import thumbnailImage4 from "../assets/img/portfolio/thumbnails/4.jpg";
import thumbnailImage5 from "../assets/img/portfolio/thumbnails/5.jpg";
import thumbnailImage6 from "../assets/img/portfolio/thumbnails/6.jpg";
import thumbnailImage7 from "../assets/img/portfolio/thumbnails/7.jpg";

import { programas } from "../data/programas";
import { ciudades } from "../data/ciudades";

const Home = () => {
    return (
        <div id="page-top">
        

            {/* Estilos CSS Integrados para Portfolio */}
            <style>
                {`
          /* Estilos para la sección de Portfolio */
          #portfolio {
            padding: 60px 0;
          }

          .portfolio-box {
            position: relative;
            display: block;
            overflow: hidden;
            cursor: pointer;
          }

          .portfolio-box img {
            width: 100%;
            height: auto;
            transition: transform 0.5s ease;
          }

          .portfolio-box:hover img {
            transform: scale(1.1);
          }

          .portfolio-box-caption {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 123, 255, 0.8); /* Color primario con opacidad */
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            text-align: center;
            padding: 1rem;
          }

          .portfolio-box:hover .portfolio-box-caption {
            opacity: 1;
          }

          .project-category {
            font-size: 1.1rem;
            margin-bottom: 0.5rem;
          }

          .project-name {
            font-size: 1.5rem;
            font-weight: bold;
          }

          /* Estilos para el botón flotante de WhatsApp */
          .whatsapp-float {
            position: fixed;
            width: 60px;
            height: 60px;
            bottom: 40px;
            right: 40px;
            background-color: #28a745; /* Color de éxito */
            color: #fff;
            border-radius: 50px;
            text-align: center;
            font-size: 30px;
            box-shadow: 2px 2px 3px #999;
            z-index: 100;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-color 0.3s ease;
          }

          .whatsapp-float:hover {
            background-color: #218838; /* Color de éxito oscuro al hover */
          }

          .whatsapp-float i {
            pointer-events: none;
          }
        `}
            </style>


            {/* Masthead */}
            <header className="masthead">
                <div className="container px-4 px-lg-5 h-100">
                    <div className="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-8 align-self-end">
                            <h1 className="text-dark font-weight-bold">
                                Educando hacia la excelencia
                            </h1>
                            <hr className="divider" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <p className="text-dark-75 mb-5">
                                Descubre nuestra oferta académica y empieza a
                                construir tu futuro
                            </p>
                            <a
                                className="btn btn-primary btn-xl"
                                href="#contact"
                            >
                                Pre-inscribirme
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            {/* About Section */}
            <section id="about" className="page-section bg-primary">
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-lg-8 text-center">
                            <h2 className="text-white mt-0">
                                Tenemos lo que tú necesitas!
                            </h2>
                            <hr className="divider divider-light" />
                            <p className="text-white-75 mb-4">
                                Potencia tus habilidades con nuestros programas,
                                actualiza tus conocimientos, y domina nuevas
                                técnicas en la disciplina que necesitas;
                                Certifica tus saberes y mantente al día en tu
                                campo de interés. ¡Da el paso hacia el éxito con
                                nosotros!
                            </p>
                            <a
                                className="btn btn-light btn-xl"
                                href="#services"
                            >
                                ¡Vamos ahora!
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Programa de Bachillerato Académico para Adultos */}
            <section id="bachillerato" className="page-section bg-light">
                <div className="container px-4 px-lg-5">
                    <div className="row g-5 align-items-center">
                        {/* Columna de la Imagen */}
                        <div className="col-lg-6">
                            <img
                                src={thumbnailImage2}
                                alt="Bachillerato Académico para Adultos"
                                className="img-fluid rounded"
                            />
                        </div>
                        {/* Columna de la Información */}
                        <div className="col-lg-6">
                            <h2 className="text-primary mb-1">Programa de</h2>
                            <h1 className="text-primary mb-1">
                                <strong className="text-black">
                                    Bachillerato Académico
                                </strong>
                            </h1>
                            <h2 className="text-primary mb-4">
                                Semipresencial y Virtual para Adultos
                            </h2>
                            <p className="lead">
                                ¡Obtén tu bachillerato en{" "}
                                <strong>corto tiempo</strong> y con la
                                flexibilidad del <strong>trabajo remoto</strong>
                                ! Nuestro programa está diseñado para adaptarse
                                a tus necesidades como adulto trabajador,
                                permitiéndote estudiar a tu propio ritmo y desde
                                cualquier lugar.
                            </p>
                            <ul className="list-unstyled mb-4">
                                <li>
                                    <i className="bi-check-circle text-success me-2"></i>
                                    <strong>Estudia con tecnología:</strong>{" "}
                                    Accede a todos los materiales y clases desde
                                    tu computadora o dispositivo móvil.
                                </li>
                                <li>
                                    <i className="bi-check-circle text-success me-2"></i>
                                    <strong>Horarios Flexibles:</strong> Adapta
                                    tus estudios a tu horario laboral y
                                    personal.
                                </li>
                                <li>
                                    <i className="bi-check-circle text-success me-2"></i>
                                    <strong>Certificación Reconocida:</strong>{" "}
                                    Obtén un título válido y reconocido que
                                    potenciará tu carrera profesional.
                                </li>
                                <li>
                                    <i className="bi-check-circle text-success me-2"></i>
                                    <strong>Soporte Continuo:</strong> Asesoría
                                    y apoyo académico durante todo tu proceso de
                                    aprendizaje.
                                </li>
                            </ul>
                            {/* Usa Link en lugar de a href */}
                            
                            <Link
                                className="btn btn-primary btn-xl"
                                to="/bachillerato"
                            >
                                ¡Más Información!
                            </Link>
                        </div>
                    </div>
                </div>
            </section>


            {/* Services Section */}
            <section id="services" className="page-section">
                <div className="container px-4 px-lg-5">
                    <h2 className="text-center mt-0">
                        Siempre comprometidos a brindar una educación de alta
                        calidad
                    </h2>
                    <hr className="divider" />
                    <div className="row gx-4 gx-lg-5">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <div className="mb-2">
                                    <i className="bi-person-lines-fill fs-1 text-primary"></i>
                                </div>
                                <h3 className="h4 mb-2">
                                    Docentes Capacitados
                                </h3>
                                <p className="text-muted mb-0">
                                    Contamos con un equipo docente altamente
                                    capacitado y comprometido con tu
                                    aprendizaje.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <div className="mb-2">
                                    <i className="bi-laptop fs-1 text-primary"></i>
                                </div>
                                <h3 className="h4 mb-2">Última Tecnología</h3>
                                <p className="text-muted mb-0">
                                    Utilizamos apps móviles, página web y
                                    recursos online para ofrecerte una educación
                                    moderna y accesible.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <div className="mb-2">
                                    <i className="bi-people fs-1 text-primary"></i>
                                </div>
                                <h3 className="h4 mb-2">
                                    Bachillerato para Adultos
                                </h3>
                                <p className="text-muted mb-0">
                                    Ofrecemos el programa de bachillerato
                                    diseñado por ciclos específicamente para
                                    adultos que desean completar su educación,
                                    con horarios flexibles y corta duración.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="mt-5">
                                <div className="mb-2">
                                    <i className="bi-award fs-1 text-primary"></i>
                                </div>
                                <h3 className="h4 mb-2">
                                    Técnicos y Educación Continuada
                                </h3>
                                <p className="text-muted mb-0">
                                    Nuestros programas técnicos y cursos de
                                    educación continuada te preparan para el
                                    éxito en el campo laboral.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Portfolio Section */}
            <section id="portfolio" className="container-fluid p-0">
                <div className="row g-0">
                    {[
                        {
                            image: thumbnailImage1,
                            alt: "Formación en Teología",
                            title: "Formación Académica en Teología",
                            link: "/teologia",
                        },
                        {
                            image: thumbnailImage7,
                            alt: "Auxiliar Contable",
                            title: "Técnico Laboral en Auxiliar Contable",
                            link: "/contable",
                        },
                        {
                            image: thumbnailImage3,
                            alt: "Primera Infancia",
                            title: "Técnico Laboral en Primera Infancia",
                            link: "/infancia",
                        },
                        {
                            image: thumbnailImage4,
                            alt: "Auxiliar Administrativo",
                            title: "Técnico Laboral en Auxiliar Administrativo",
                            link: "/administrativo",
                        },
                        {
                            image: thumbnailImage5,
                            alt: "Música",
                            title: "Conocimientos Académicos en Música",
                            link: "/musica",
                        },
                        {
                            image: thumbnailImage6,
                            alt: "Inglés",
                            title: "Conocimientos Académicos en Inglés",
                            link: "/ingles",
                        },
                    ].map((item, index) => (
                        <div className="col-lg-4 col-sm-6" key={index}>
                            <div className="portfolio-box">
                                <a href={item.link} title={item.title}>
                                    <img
                                        className="img-fluid"
                                        src={item.image}
                                        alt={item.alt}
                                    />
                                    <div className="portfolio-box-caption">
                                        <div className="project-category text-white-50">
                                            Estudia
                                        </div>
                                        <div className="project-name">
                                            {item.title.split(" en ").pop()}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Call to Action */}
            <section
                id="download"
                className="page-section bg-dark text-white text-center"
            >
                <div className="container px-4 px-lg-5">
                    <h2 className="mb-4">
                        Descarga la App para Android o IOS!
                    </h2>
                    <a
                        className="btn btn-light btn-xl m-3"
                        href="https://play.google.com/store/apps/details?id=com.jotappp.reactcep"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar Android
                    </a>
                    <a
                        className="btn btn-light btn-xl"
                        href="https://www.apple.com/co/app-store/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Descargar iPhone
                    </a>
                </div>
            </section>

            {/* Contact Section */}
            <section className="page-section pt-10" id="contact">
                <div className="container px-4 px-lg-5">
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-lg-8 col-xl-6 text-center">
                            <h2 className="mt-0">Pre-Inscríbete ahora!</h2>
                            <hr className="divider" />
                            <label>
                                Déjanos tu mensaje, pronto nos estaremos
                                contactando contigo!
                            </label>
                        </div>
                    </div>
                    {/* Formulario */}
                    <div className="row gx-4 gx-lg-5 justify-content-center mb-5">
                        <div className="col-lg-6">
                            <form
                                id="contactForm"
                                action="https://script.google.com/macros/s/AKfycbzmLjjR-U3BXvhIZ-X0AIjIy4cWFDzod--SWumjI2BP-ZlcFkXzYyKk60lL7E7ueKLibA/exec"
                                method="post"
                            >
                                {/* Selección de Programa */}
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name="program"
                                        aria-label="Selecciona un programa"
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Selecciona un programa
                                        </option>
                                        {programas.map((programa, index) => (
                                            <option
                                                key={index}
                                                value={programa}
                                            >
                                                {programa}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="program">
                                        Programa de Interés
                                    </label>
                                    <div className="invalid-feedback">
                                        Selecciona un programa.
                                    </div>
                                </div>

                                {/* Nombre */}
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        type="text"
                                        placeholder="Introduce tu nombre..."
                                        required
                                    />
                                    <label htmlFor="name">Tu Nombre</label>
                                    <div className="invalid-feedback">
                                        Se requiere un Nombre.
                                    </div>
                                </div>

                                {/* Número de WhatsApp */}
                                <div className="form-floating mb-3">
                                    <input
                                        className="form-control"
                                        id="phone"
                                        name="phone"
                                        type="tel"
                                        placeholder="(123) 456-7890"
                                        required
                                    />
                                    <label htmlFor="phone">
                                        Número de WhatsApp
                                    </label>
                                    <div className="invalid-feedback">
                                        Se requiere un número.
                                    </div>
                                </div>

                                {/* Selección de Ciudad */}
                                <div className="form-floating mb-3">
                                    <select
                                        className="form-select"
                                        name="city"
                                        aria-label="Selecciona una Ciudad"
                                        required
                                    >
                                        <option value="" disabled selected>
                                            Selecciona una Ciudad
                                        </option>
                                        {ciudades.map((ciudad, index) => (
                                            <option key={index} value={ciudad}>
                                                {ciudad}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="city">
                                        Tu Ciudad o Municipio
                                    </label>
                                    <div className="invalid-feedback">
                                        Selecciona una ciudad.
                                    </div>
                                </div>

                                {/* Mensaje */}
                                <div className="form-floating mb-3">
                                    <textarea
                                        className="form-control"
                                        id="message"
                                        name="message"
                                        placeholder="Introduce tu mensaje aquí..."
                                        style={{ height: "10rem" }}
                                        required
                                    ></textarea>
                                    <label htmlFor="message">Tu mensaje</label>
                                    <div className="invalid-feedback">
                                        Falta tu mensaje.
                                    </div>
                                </div>

                                {/* Mensajes de Éxito/Error */}
                                <div
                                    id="submitSuccessMessage"
                                    className="d-none text-center mb-3"
                                >
                                    <div className="fw-bolder">
                                        Su Inscripción se realizó correctamente
                                    </div>
                                    pronto nos comunicaremos contigo!
                                    <br />
                                </div>
                                <div
                                    id="submitErrorMessage"
                                    className="d-none text-center text-danger mb-3"
                                >
                                    Error enviando el mensaje!
                                </div>

                                {/* Botón de Envío */}
                                <div className="d-grid">
                                    <button
                                        className="btn btn-primary btn-xl"
                                        id="submitButton"
                                        type="submit"
                                    >
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* Información de Contacto */}
                    <div className="row gx-4 gx-lg-5 justify-content-center">
                        <div className="col-lg-4 text-center mb-5 mb-lg-0">
                            <i className="bi-phone fs-2 mb-3 text-muted"></i>
                            <div>+57 (321) 620-0693</div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Botón Flotante de WhatsApp */}
            <a
                className="whatsapp-float"
                href="https://wa.me/573216200693?text=Hola%2C%20tengo%20inter%C3%A9s%20por%20el%20Programa"
                target="_blank"
                rel="noopener noreferrer"
                title="Chatea con nosotros en WhatsApp"
            >
                <i className="bi-whatsapp fs-3"></i>
            </a>

          
           
        </div>
    );
};

export default Home;
