import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Bachillerato from "./pages/bachillerato";
import Administrativo from "./pages/administrativo";
import Infancia from "./pages/infancia";
import Contable from "./pages/contable";
import ContactForm from "./pages/preinscripcion";
import Header from "./components/Header";
import "./scss/styles.scss";
import Footer from "./components/Footer";
import ScrollToTop from './components/ScrollToTop';
import Ingles from "./pages/ingles";
import Musica from "./pages/musica";
import Teologia from "./pages/teologia";
import Success from "./pages/success";


function App() {
    return (
        <Router>
           <ScrollToTop />
            <Header />
            <div className="content-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/bachillerato" element={<Bachillerato />} />
                    <Route path="/preinscripcion" element={<ContactForm />} />
                    <Route path="/administrativo" element={<Administrativo />} />
                    <Route path="/musica" element={<Musica />} />
                    <Route path="/ingles" element={<Ingles />} />
                    <Route path="/infancia" element={<Infancia />} />
                    <Route path="/contable" element={<Contable />} />
                    <Route path="/teologia" element={<Teologia />} />
                    <Route path="/success" element={<Success />} />
                </Routes>
            </div>
            <Footer />
        </Router>
    );
}

export default App;
